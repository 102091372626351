.nav-wrapper {
}

nav {
  font-family: ff-meta-web-pro, sans-serif;
  position: fixed;
  top: 0;
  height: var(--header-height);
  /* height: 6vh; */
}

.glass nav,
.painting nav,
.shop-page nav,
.contact-page nav {
  background-color: white;
}

nav ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.nav-top-menu {
  margin-top: 1em;
  height: 8vh;
  width: 100vw;
  display: flex;
  justify-content: space-evenly;
  align-items: flex-start;
}

.top-menu-item {
  flex: 1;
}

nav .menu-header {
  font-size: large;
  font-weight: 600;
  text-decoration-line: none;
  text-decoration-color: #a2a3a4;
  text-transform: uppercase;
  padding: 0;
  pointer-events: all;
  cursor: pointer;
}

nav p {
  margin: 0;
}

nav ul a {
  text-decoration: none;
  pointer-events: all;
  color: #777;
}

nav ul a:visited {
  text-decoration: none;
  pointer-events: all;
  color: #999;
}

nav li.menu-header:hover,
nav li.dropdown:hover {
  text-decoration-line: underline;
}

.main-title h1 {
  transition: linear 400ms;
}

.topic-menu {
  flex: 1;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 0;
  height: 100%;
  cursor: pointer;
  transition: 1s linear;
}

nav .topic-menu ul.dropdown {
  flex-direction: column;
  transition: 1s;
}

nav .top-menu ul.dropdown:first-child {
  height: 2em;
  opacity: 1;
}

nav .topic-menu li.dropdown {
  text-transform: capitalize;
  margin: 0;
  opacity: 0;
  height: 0;
  transition: all 500ms 100ms;
  color: #777;
}

.glass .topic-menu li.dropdown,
.painting .topic-menu li.dropdown .shop-page .topic-menu li.dropdown,
.contact-page .topic-menu li.dropdown {
  background-color: white;
}

nav .topic-menu:hover li.dropdown {
  opacity: 1;
  height: 2em;
}

.footer-text {
  position: sticky;
  bottom: 100vh;
  top: calc(100vh - var(--footer-height));
  height: var(--footer-height);
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 1em;
  pointer-events: all;
  background-color: #484848;
}

.footer p {
  font-family: ff-meta-web-pro, sans-serif;
  font-weight: 400;
  font-style: normal;
  text-transform: lowercase;
  color: rgba(180, 180, 180, 1);
}

h4.site-copy {
  font-style: normal;
}

.site-copy.email {
  font-style: italic;
}

.footer .copywrite {
  font-size: 0.8em;
}

@media (max-width: 825px) {
  .main-title h1 {
    font-size: 1.5em;
  }
}

@media (max-width: 624px) {
  nav .nav-top-menu {
    flex-direction: column;
    width: 100%;
  }
}
