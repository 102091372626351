.title {
  margin-top: 2em;
  color: black;
  font-family: ff-meta-web-pro, sans-serif;
  font-size: 25px;
  font-style: italic;
}
.format {
  color: grey;
  font-family: ff-meta-web-pro, sans-serif;
  text-align: left;
}
.mediatype {
  text-align: left;
  color: grey;
  text-transform: capitalize;
}
.size {
  font-family: ff-meta-web-pro, sans-serif;
  color: grey;
  text-align: left;
}

.purchase {
  margin-top: 3em;
}

.price {
  font-family: ff-meta-web-pro, sans-serif;
  color: grey;
  font-style: bold;
  margin-top: 5em;
}

.details-page {
  height: 100vh;
  width: 100vw;
  margin: auto;
}

.single-wrapper {
  display: flex;
  justify-content: center;
  align-content: center;
  margin: auto;
  padding: 2vh;
  height: 100vh;
  width: 98vw;
}

.details-page .bread-crumb {
  font-family: ff-meta-web-pro, serif;
  padding: 1rem;
  margin-top: 5rem;
  color: gray;
  font-size: 1.1rem;
  font-weight: bold;
  letter-spacing: 0.015rem;
  text-align: start;
  text-decoration: none;
  align-self: flex-start;
  text-transform: uppercase;
}

.details-page .bread-crumb::before {
  content: '<';
  font-weight: 700;
  padding: 0.25rem;
}

.details-page img {
  flex: 1;
  max-height: 100%;
  max-width: 100%;
  width: unset;
  align-self: center;
}

.details-page .image-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 1rem;
  padding: 10rem;
  padding-right: 1rem;
}

.details-page .information {
  flex: 1;
  padding: 1rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: -2;
}

@media (max-width: 624px) {
  .single-wrapper {
    flex-direction: column;
    justify-content: center;
  }
  .details-page .information {
    align-self: unset;
  }
}
