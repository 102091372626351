.carouselContainer {
  --inset1: 2.5em;
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  left: 0;
  width: var(--one-column-width);
  margin: auto;
}

.carousel-title {
  flex-basis: 100%;
  height: 20%;
  align-self: flex-start;
  margin-left: var(--inset1);
  font-weight: bolder;
  margin-bottom: 5px;
}

.carousel-inner-container {
  flex: 0;
  display: flex;
  flex-direction: row;
}

.imageArrayContainer {
  flex: 1 0;
  display: flex;
  height: 30vh;
  overflow-x: scroll;
  overflow-y: hidden;
  scroll-behavior: smooth;
}

.imageArray {
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
}

.image-wrapper {
  /* flex: 0 1; */
  height: 100%;
}

.carousel-image {
  height: 100%;
  object-fit: contain;
  margin: 0.2em;
}

.perSeButton {
  flex: 0 1;
  align-self: center;
  height: 80px;
  opacity: 0.6;
  border: none;
  background: none;
  font-size: 80px;
  line-height: 55px;
  color: #acacac;
  transition: opacity 200ms linear;
}

.perSeButton:hover {
  opacity: 1;
  cursor: pointer;
}

.perSeButton:focus {
  border: none;
  outline: none;
}
.outline {
  outline: 3px solid green;
  outline-offset: -3px;
}
