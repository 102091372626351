* {
  box-sizing: border-box;
}

body {
  overflow-x: hidden;
  scrollbar-width: none;
  -ms-overflow-style: none;
  --header-height: 12vh;
  --footer-height: 7vh;
  --one-column-width: 80vw;
  --content-height: calc(100vh - 12vh - 14vh);
}

body::-webkit-scrollbar {
  display: none;
}

h1 {
  font-family: ff-scala, serif;
  font-weight: 400;
  font-style: normal;
  font-size: xxx-large;
  text-transform: uppercase;
  display: inline;
  color: #777;
  vertical-align: middle;
}

h2 {
  font-family: ff-meta-web-pro, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 1.2em;
  /* text-transform: uppercase; */
  display: inline;
  color: rgba(112, 112, 112, 1);
  vertical-align: middle;
}

h3 {
  font-family: ff-scala, serif;
  font-weight: 400;
  font-style: normal;
  font-size: xxx-large;
  text-transform: uppercase;
  display: inline;
  color: rgba(112, 112, 112, 1);
  vertical-align: middle;
  line-height: 4em;
}

h4 {
  font-family: ff-scala, serif;
  font-weight: 400;
  font-style: italic;
  text-transform: uppercase;
  margin: none;
  color: rgba(180, 180, 180, 1);
}

h5 {
  font-family: ff-scala, serif;
  font-size: 1.2em;
  font-style: italic;
  font-weight: 700;
  text-transform: uppercase;
  margin: 0;
  margin-top: 2rem;
  color: #777;
}

h5 + p,
h5 + ul,
h5 + ul p {
  margin-top: 0.25rem;
  margin-bottom: 1rem;
}

a {
  color: #222;
}

li {
  line-height: 1.5em;
}

.container {
  height: 100%;
  width: 100%;
}

.image-title {
  flex: 1;
  font-style: italic;
  font-size: 100;
  text-transform: capitalize;
  color: #656764;
  margin-bottom: 0;
  margin-top: 0.5em;
  margin-left: 0;
  text-align: left;
}

.banner-image-wrapper {
  background-image: url(./Assets/banner.jpg);
  background-position-x: center;
  background-position-y: bottom;
  height: 100vh;
  width: 100vw;
  top: 0;
  overflow: hidden;
  background-repeat: no-repeat;
  background-size: cover;
}

.filter-term {
  flex: 1;
  font-style: normal;
  font-size: 100;
  text-transform: capitalize;
  color: #656764;
  margin-bottom: 0;
  margin-top: 0.5em;
  margin-left: 0;
  text-align: right;
}

.row {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  flex: 1;
}

.row.narrow {
  margin-top: 0.5em;
}

.reverse-text {
  color: white;
}

.site-copy {
  margin: 0 1em;
}

.page-wrapper {
  height: 100%;
}

.content {
  position: fixed;
  width: 100%;
  height: calc(100vh - var(--header-height) - var(--footer-height));
  top: var(--header-height);
  overflow-y: scroll;
}

.content.painting {
  padding-bottom: 1em;
}

.content.glass {
  margin-bottom: 1em;
}

.content.shop {
  z-index: -1;
  overflow: hidden;
}

.image {
  width: 100%;
  object-fit: contain;
}

.image.lazyloaded {
  background-color: unset !important;
}

.image-frame {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex: 0 1;
  height: 60%;
  margin: 1em;
}

.image-row-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  min-width: 15%;
  justify-content: space-between;
}

.image-row-meta-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  margin: var(--header-height) 10% 5% 10%;
}

button {
  outline: none;
  border: none;
  background-color: transparent;
}

/* Contact */

.contact-detail {
  width: 70vw;
  margin: var(--header-height) auto 5% auto;
}
.contact-detail h5 {
  text-align: center;
}

.contact-detail p {
  font-family: ff-meta-web-pro, sans-serif;
  font-size: 1rem;
}

.contact-detail ul {
  list-style: none;
  margin-block-start: 0;
  margin: 0;
  padding: 0;
  text-align: center;
}

.contact-detail a {
  text-decoration: none;
}

.contact-detail a:hover {
  text-decoration: underline;
}

.contact-detail .sub-head {
  color: #777;
  font-size: 1.2em;
  text-align: center;
  list-style: none;
}

.contact-detail .about-section {
  display: flex;
  flex-direction: row;
}

.contact-detail .bio-section {
  flex: 2;
  text-align: left;
  margin: 0.5em;
}

.contact-detail .profile-images {
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  margin: 2em;
}

.contact-detail .image-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 2em 1em 2em 0;
}

.contact-detail img {
  flex: 1;
  max-width: 100%;
  object-fit: cover;
}

@media (max-width: 600px) {
  .contact-detail .profile-images {
    flex-direction: column;
    margin: auto;
  }
}

@media (max-width: 1000px) {
  .contact-detail .bio-section {
    flex-direction: column;
  }
}

/* Events */

.picture-container {
  width: 50vw;
  margin: auto;
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  max-width: 50%;
  justify-content: space-evenly;
}

.image-container {
  margin: auto;
  flex: 1;
  display: flex;
  gap: 1em;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

.event-header {
  text-align: center;
  margin-top: 2em;
}

h3.event-title {
  font-size: 2.5em;
  text-align: center;
  line-height: 1.5em;
  color: #777;
}

.event-date {
  font-family: ff-meta-web-pro, sans-serif;
  text-align: center;
  font-size: 1.25em;
  margin-top: -1em;
  margin-bottom: 1.5em;
  color: #777;
  line-height: 2em;
}

.event-date .hours {
  font-size: 0.8em;
  color: #777;
}
.event-header ul {
  list-style: none;
  line-height: 1.2em;
  margin-top: 0;
}

.img {
  flex: 1;
  object-fit: cover;
  max-width: 40%;
  margin-top: 0;
  margin-bottom: 2em;
}
