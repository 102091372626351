.thumbnail-container {
  position: relative;
  height: 90vh;
  width: 100vw;
  overflow: hidden;
  padding: 1em;
  z-index: 8;
}

.thumbnail-row {
  height: 40vh;
  display: flex;
  justify-content: center;
  align-items: space-evenly;
}

.thumbnail-wrapper {
  flex: 1;
  margin: 1em;
  display: flex;
  position: relative;
  overflow: hidden;
}

.overlay {
  position: absolute;
  top: auto;
  bottom: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity 200ms linear;
}

.overlay:hover {
  opacity: 1;
}

.overlay-shade {
  position: absolute;
  background-color: #2d2d2d90;
  width: 100%;
  height: 100%;
  padding: 1em;
  pointer-events: none;
}

.thumbnail-title {
  font-family: ff-meta-web-pro, serif;
  top: 0;
  flex: 1;
  text-align: center;
  font-size: 2em;
  font-weight: lighter;
  color: white;
  opacity: 1;
  z-index: 10;
  text-shadow: 1px 1px 6px #626362;
  pointer-events: all;
  user-select: none;
  cursor: pointer;
}

.thumbnail-image {
  width: 100%;
  object-fit: cover;
}
